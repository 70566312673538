import React from 'react'
import { FormattedMessage } from 'react-intl'

import { ImageZoom } from '../ImageZoom/ImageZoom'

import industryChile from './assets/images/chile.svg'
import industryColombia from './assets/images/colombia.svg'
import industryPeru from './assets/images/peru.svg'
import industryEcuador from './assets/images/ecuador.svg'
import industryMexico from './assets/images/mexico.svg'

const MDPIndustries: React.FC = () => {
  return (
    <section className="container py-layout-4">
      <div className="row">
        <div className="col-12">
          <div>
            <h2 className="text-center text-primary">
              <FormattedMessage id="mdp.industries.title" />
            </h2>
            <p className="px-0 px-lg-layout-7 text-center">
              <FormattedMessage id="mdp.industries.description" />
            </p>
          </div>   
        </div>
        <div className="col-12">
          <div className="d-flex justify-content-around flex-wrap">
            <ImageZoom src={industryChile} alt="Chile" />
            <ImageZoom src={industryColombia} alt="Colombia" />
            <ImageZoom src={industryEcuador} alt="Ecuador" />
            <ImageZoom src={industryMexico} alt="Mexico" />
            <ImageZoom src={industryPeru} alt="Peru" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default MDPIndustries