import React, {useContext, useState} from 'react';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl'
import LocalizedLink from '../../LocalizedLink/LocalizedLink';
import LocaleContext from '../../../contexts/LocaleContext';
import './styles.scss'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#___gatsby');

function ModalMessage({modalIsOpen, setIsOpen}) {
  
  const localeCode = useContext(LocaleContext);
  function closeModal() {
    setIsOpen(false);
  }

  // const [ btnDownloadState, setBtnDownloadState ] = useState(localeCode === 'es' ? 'Descargar' : 'Download');
  const [ btnState, setBtnState ] = useState(localeCode === 'es' ? 'Enviar' : 'Send');



  return (
    <div >
      <input type="submit" className="submit-buttton" value={ btnState } />
      <Modal
        className='modal'
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <h2 className='modal-title'><FormattedMessage id="form.modal.title" /></h2>
        <button className='close-modal-btn' onClick={closeModal}>x</button>
        <div>
          <p className='modal-desc'><FormattedMessage id="form.modal.blog.description_mdp" /></p>
        </div>
        <div className='modal-blog-button-container'>
          <LocalizedLink
              to="/martes-de-producto"
              className="button-faq"
              activeClassName="active"
            >
          <FormattedMessage id="form.modal.button-home" />
          </LocalizedLink>
        </div>
      </Modal>
    </div>
  );
}

export default ModalMessage;