import React from 'react'

import WorldImg from './assets/images/world.png'
import TramaImg from './assets/images/trama.png'
import './styles.scss'
import { FormattedMessage } from 'react-intl'

const MDPHero: React.FC = () => {
  return (
    <section className="mdp__bg-hero hero pt-layout-2 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-md-6  col-12 col-xm-12 d-flex flex-column justify-content-center align-items-center">
            <h1 className="text-white">
              <FormattedMessage id="mdp.hero.title" />
            </h1>
            <p className="text-white" ><FormattedMessage id="mdp.hero.description" /></p>
          </div>
          <div className="col-md-6 d-md-block d-none d-sm-none d-xm-none">
            <figure>
              <img src={WorldImg} alt="world-kushki" />
            </figure>
          </div>
        </div>
      </div>
      <div className="mdp__contain-trama"></div>
    </section>
  )
}

export default MDPHero