import React from 'react'
import { FormattedMessage } from 'react-intl'


import WeAreImg from './assets/images/somos.inline.svg'
import arrowImg from './assets/images/forma.png'

import './styles.scss'

const MDPAbout:React.FC = () => {
  return (
    <section className="mdp__bg py-layout-3">
      <div className="container">
        <div className="row d-flex flex-column align-items-center">
          <h2 className="text-center text-primary">
            <FormattedMessage id="mdp.about.title" />
          </h2>
          <img className="py-layout-1" src={arrowImg} alt="arrow-image" />
        </div>
      </div>
      <section className="container ">
        <div className="row">
          <div className="col-md-5">
            <p className="mdp__about-text text-primary">
              <FormattedMessage id="mdp.about.item_text1" />
            </p>
            <p className="mdp__about-text text-primary">
              <FormattedMessage id="mdp.about.item_text2" />
            </p>
            <p className="mdp__about-text text-primary">
              <FormattedMessage id="mdp.about.item_text3" />
            </p>
          </div>
          <div className="col-md-2 d-flex justify-content-center">
            <div className="vl"></div>
          </div>
          <div className="col-md-5">
            <WeAreImg className="mdp__about-image" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="">
              <iframe className="campaign-video my-layout-3"
              src="https://www.youtube.com/embed/f4WANvbMyuE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

export default MDPAbout