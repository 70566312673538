import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ImageZoom } from '../ImageZoom/ImageZoom'

import postImg1 from './assets/images/post1.svg'
import postImg2 from './assets/images/post2.svg'
import postImg3 from './assets/images/post3.svg'
import postImg4 from './assets/images/MDP-2023.jpg'

import './styles.scss'
import LocalizedLink from '../../LocalizedLink/LocalizedLink'

const MDPBanner: React.FC = () => {
  return (
    <section className="mdp__banner-bg py-layout-4">
      <section className="container">
        <div className="row">
          <div className="col-12">
            <h2>
              <FormattedMessage id="mdp.banner.title" />
            </h2>
            <p className="text-white mdp__banner-bg-description">
              <FormattedMessage id="mdp.banner.description" />
            </p>
          </div>
          <div className="col-12">
            <div className="d-flex justify-content-around flex-wrap">
              <ImageZoom src={postImg1} alt="Tendencias" />
              <ImageZoom src={postImg2} alt="Certificado" />
              <ImageZoom src={postImg3} alt="Retos" />
              <ImageZoom className="image-banner" src={postImg4} alt="Latinoamérica digitalizada" />
            </div>
          </div>
          <div className="col-12">
            <LocalizedLink to="/blog">
              <div className="mdp__banner-contain_link my-layout-2">
                <p className="text-white text-center mdp__text-link " >
                  <FormattedMessage id="mdp.banner.text_link" />
                </p>
              </div>
            </LocalizedLink>
          </div>
        </div>
      </section>
    </section>
  )
}

export default MDPBanner