import React from 'react'
import IndexLayout from '../layouts'
import { LocaleTypes } from '../data/locales';
import MDPHero from '../components/MDP/MDPHero/MDPHero';
import MDPAbout from '../components/MDP/MDPAbout/MDPAbout';
import MDPIndustries from '../components/MDP/MDPIndustries/MDPIndustries';
import MDPBanner from '../components/MDP/MDPBanner/MDPBanner';
import Footer from '../components/Footer/Footer';
import MDPForm from '../components/MDP/MDPForm/MDPForm';


interface MDPProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}
const MartesDeProducto: React.FC<MDPProps> = ({pathContext, location}) => {


  const content = () => (
    <>
      <MDPHero />

      <MDPAbout />

      <MDPIndustries />

      <MDPBanner />

      <MDPForm />

      <Footer theme={'primary'} />

    </>
  )


  return (
    <IndexLayout locale={pathContext.localeCode} location={location} render={content} />
  )
}

export default MartesDeProducto