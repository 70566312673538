import React from 'react'
import Form from './Form'
import { FormattedMessage } from 'react-intl'
import './styles.scss'

import ChartImg from './assets/images/Ilustracion.inline.svg'

const MDPForm: React.FC = () => {
  return (
    <section className="container">
      <div className="row">
        <div className="col-md-6 py-sm-layout-2 py-lg-layout-5">
          <h2 className="mdp__form-title">
            <FormattedMessage id="mdp.form.title" />
          </h2>
          <p className="mdp__form-description">
            <FormattedMessage id="mdp.form.description" />
          </p>
          <ChartImg className="mdp__form-img" />
        </div>
        <div className="col-md-6">
          <Form/>
        </div>
      </div>
    </section>
  )
}

export default MDPForm